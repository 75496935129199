<template>
  <div class="site-wrap">
    <template v-if="applicationReady">
      <site-header></site-header>
      <page-top>
        Buckeye Portal
      </page-top>
      <div style="min-height: 60vh">
        <router-view role="main" />
        <authentication-failure-popup-modal
          ref="authenticationFailureModal"
          @close="handleModalClose"
        />
        <unauthorized-popup-modal
          ref="unauthorizedModal"
          @close="handleUnauthorizedModalClose"
        />
      </div>
      <site-footer :plugin-version="appStore.getPluginVersion"></site-footer>
    </template>
    <div class="text-center my-5 h3" v-else>
      Loading application...
    </div>
  </div>
</template>
<script lang="js">
import SiteHeader from "@/src/components/shared/SiteHeader.vue";
import SiteFooter from "@/src/components/shared/SiteFooter.vue";
import {useAppCoreStore} from "@/src/stores/app-core";
import PageTop from "@/src/components/shared/PageTop.vue";
import AuthenticationFailurePopupModal from '@/src/components/AuthenticationFailurePopupModal.vue'
import UnauthorizedPopupModal from "@/src/components/UnauthorizedPopupModal.vue";
import { modalActionsMixin } from "./mixins/modalActionsMixin";
export default {
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  mixins: [ modalActionsMixin ],
  components: {
    SiteHeader,
    SiteFooter,
    PageTop,
    AuthenticationFailurePopupModal,
    UnauthorizedPopupModal
  },
  data() {
    return {
      currentUser: null,
      loading: false,
      error: null,
      modalMessage: null
    }
  },
  computed: {
    applicationReady() {
      return !this.loading && this.appStore.currentUser;
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.appStore.loadApplicationData();
      await this.appStore.loadCurrentUser();
      this.checkPermissions()
    } catch(error) {
      this.error = error;
    }
    this.loading = false;
  },
  methods: {
    // If we have a user and the route we are on requires permissions then check them and redirect if not allowed
    checkPermissions() {
      if(this.appStore.currentUser && this.$route.meta.permissions) {
        this.$route.meta.permissions.forEach((permission) => {
          if(this.$appStore.currentUser.canDo(permission, this.$route.meta.permissionsKey ? this.$route.meta.permissionsKey : 'read')) {
            return
          }
        })
        this.$router.push({name: 'app-root'})
      }
    },
    handleModalClose() {
      window.location.reload();
    },
    handleUnauthorizedModalClose() {
      // Close any background modals that might have been open when the user was unauthorized
      this.closeAllModals()      
      this.$router.push({name: 'app-root'})
    },
    displayLogoutModal() {
      $('#authenticationFailureModal').modal();
    },
    displayUnauthorizedModal() {
      $('#unauthorizedModal').modal();
    }
  },
  created() {
    window.displayLogoutModal = this.displayLogoutModal;
    window.displayUnauthorizedModal = this.displayUnauthorizedModal;
  }
}

</script>
