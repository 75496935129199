<template>
  <CardSection :loading="isSavableStateLoading"
    :error="majorError"
    header-class="title-bar">
    <template #header>
      Edit Application Settings - Robocall Reduction
    </template>

    <template #body>
      <SubSection>
        <template #body>
          <VeeForm v-if="robocallReduction" @submit="submitRobocallReduction"> 
            <RobocallReductionState :is-enabled="isEnabled" @reload="reload"/>
            <hr>
            <TextInput v-model="robocallReduction.config.unos_robocall_number"
              name="unos_robocall_number"
              label="UNOS Robocall Incoming Number"
              rules="required|phone_number"
              col-style="form-group-standard-column" 
            />
            <TextInput v-model="robocallReduction.config.issue_alert_email"
              name="issue_alert_email"
              label="Issue alert email"
              rules="required|email"
              col-style="form-group-standard-column" 
            />
            <div class="row ml-1">
              <TextInput v-model="robocallReduction.config.stop_duration"
                name="stop_duration"
                type="number"
                label="Regular Stop Duration"
                rules="required|min_value:1"
                col-style="form-group-standard-column" 
              />
              <TextInput v-model="robocallReduction.config.resend_duration"
                name="resend_duration"
                type="number"
                label="Regular Resend Interval"
                rules="required|min_value:1"
                col-style="form-group-standard-column" 
              />
            </div>
            <div class="row ml-1">
              <TextInput v-model="robocallReduction.config.expedited_stop_duration"
                name="expedited_stop_duration"
                type="number"
                label="Expedited Stop Duration"
                rules="required|min_value:1"
                col-style="form-group-standard-column" 
              />
              <TextInput v-model="robocallReduction.config.expedited_resend_duration"
                name="expedited_resend_duration"
                type="number"
                label="Expedited Resend Interval"
                rules="required|min_value:1"
                col-style="form-group-standard-column" 
              />
            </div>
            <div class="row ml-1">
              <TextInput v-model="robocallReduction.config.primary_stop_duration"
                name="primary_stop_duration"
                type="number"
                label="Primary Stop Duration"
                rules="required|min_value:1"
                col-style="form-group-standard-column" 
              /> 
              <TextInput v-model="robocallReduction.config.primary_resend_duration"
                name="primary_resend_duration"
                type="number"
                label="Primary Resend Interval"
                rules="required|min_value:1"
                col-style="form-group-standard-column" 
              />
            </div>
            <TextInput v-model="robocallReduction.config.delayed_email_threshold"
                name="delayed_email_threshold"
                type="number"
                label="Delayed Email Threshold"
                rules="required|min_value:1"
                col-style="form-group-standard-column" 
              />      
            <div class="col-lg-2 col-md-2">
              <div class="form-check form-check-inline">
                <input id="record_robocalls" 
                  v-model="robocallReduction.config.record_robocalls"
                  class="form-check-input" 
                  type="checkbox"  
                  aria-labelledby="record_robocalls">
                <label class="form-check-label" for="record_robocalls">Record Robocalls</label>
              </div>
            </div>
            <!-- save controls -->
            <ActionToolbar :component-state="currentState" 
              success-message="Successfully updated robocall reduction!"
              primary-button-text="Save"
              :secondary-enabled="false"
              :error-message="formError">
            </ActionToolbar>
          </VeeForm>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script>
import RobocallReductionState from "./_robocallReductionState.vue"
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import { Form as VeeForm } from 'vee-validate';
import { useRobocallReductionsStore } from '@/src/stores/robocall_reductions';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import SingleSelect from "@/src/components/shared/FormComponents/SingleSelect.vue";

/**
 * The component handles both the new and edit pages for Application Settings */
export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    TextInput,
    SingleSelect,
    RobocallReductionState,
    VeeForm,
  },
  mixins: [ savableStateMixin ],
  setup() {
    const robocallReductionsStore = useRobocallReductionsStore();
    return { robocallReductionsStore };
  },
  data() {
    return {
      robocallReduction: {config: {}},
      type: null,
    }
  },
  computed: {
    isEnabled() {
      return this.robocallReduction.active
    },
  },
  watch: { 
    '$route.params.id': {
      handler: function() {
        this.buildRobocallReduction();
      }
    }
  },
  async mounted() {
    let url = window.location.pathname.split('/')
    this.type = url[url.length-(this.$route.params.id ? 3 : 2)]
    this.buildRobocallReduction();
  },
   methods: {
    async buildRobocallReduction() {
      try {
        this.setSavableStateLoading();
        // on edit
        const response = await this.robocallReductionsStore.loadRobocallReduction(this.$route.params.id, this.type);
        this.setRobocallReduction(response);
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    },
    async submitRobocallReduction() {
      const payload = {
        config: this.robocallReduction.config,
        active: this.robocallReduction.active,
      }
      try {
        this.setSavableStateProcessing();
        let response;
        response = await this.robocallReductionsStore.updateRobocallReduction(payload);
        this.setRobocallReduction(response);
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },

    reload(record) {
      this.currentState = this.SaveStates.Blank;
      this.setRobocallReduction(record)
    },
    setRobocallReduction(record) {
      this.robocallReduction = {
        id: record.id,
        configCategory: record.config_category,
        config: record.config,
        active: record.active,
      }
    },
  }
}
</script>
